<template>
  <div class="rtl">
    <v-card>
      <v-card-title>
        دریافت لیست سطح مالی
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="FinancialLevel" :search="search">
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axiosApi from "@/axios";

export default {
name: "FinancialLevel",
  data(){
    return{
      FinancialLevel : [],
      UserName : '',
      search : '',
      headers: [
        {
          text: 'آی دی',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {text: 'عنوان', value: 'title'},
        {text: 'کد', value: 'code'},
        {text: 'درصد کارمزد', value: 'commissionFeePercent'},
        {text: 'حداکثر برداشت', value: 'maximumWithdrawal'},
        {text: 'کل معاملات یک ماه اخیر', value: 'totalLastMonthTransactions'},
        {text: 'تعداد', value: 'count'},
      ],
    }
  },
  mounted() {
  this.getFinancialLevel();
  },
  methods:{
    getFinancialLevel()
    {
      axiosApi().post('api/Manage/v1/Level/GetFinancialLevelList')
      .then(({data}) => {
        if (data['isSuccess'] === true)
          this.FinancialLevel = data.data
      })
    },
  }
}
</script>

<style>

</style>
